<template>
  <div
    ref="content"
    class="button_fly"
  >
    <admin-header
      :title="details.name || ''"
      :isSave="!isSave && isEditPage && this.$store.getters.userIsRoot"
    >
    </admin-header>
    <loader v-if="isLoading" />
    <div v-else>
      <div class="form mt-8 flex">
        <div
          class="flex-1"
        >
          <r-input
            class="mb-5"
            label="Наименование"
            v-model="details.name"
            @change="isChange"
          />
          <div class="mt-5 flex">
            <r-input
              class="mb-5 mr-5 flex-1"
              label="Номер устройства"
              :readonly="true"
              v-model="details.hostId"
            />
            <r-input
              class="mb-5 flex-1"
              label="Модель устройства"
              :readonly="true"
              v-model="details.host"
            />
          </div>
          <r-select
            class="mb-5"
            :items="$store.state.electric.typesSelect"
            label="Тип устройства"
            v-model.trim="$v.details.type.$model"
            @change="changeType"
            :error="$v.details.type.$error && !$v.details.type.required"
            :class="{'field_error': $v.details.type.$error && !$v.details.type.required}"
            :return-object="true"
            error-message="Поле обязательно для заполнения"
            titleValue="name"
            idValue="typeId"
          />
          <r-input
            label="Тип мониторинга"
            :readonly="true"
            v-model="details.providerName"
          />
        </div>
        <div class="ml-6 flex flex-column flex-1 overflow-hidden">
          <rir-map
            :center="details.lat && details.lng ? [details.lat, details.lng] : $cityCenter"
            @click="onMapClick"
            collapse-btn
            style="height: 415px"
            class="mb-6"
          >
            <ymap-marker
              :coords="details.lat && details.lng ? [details.lat, details.lng] : $cityCenter"
              :marker-id="'marker'"
              :icon="$markerIcon(details.type.name || 'mapPin')"
              :options="{ draggable: true }"
              @dragend="onMarkerDragEnd"
            />
          </rir-map>
          <r-input
            class="flex-1"
            label="Адрес"
            v-model.trim="details.address"
            @onPressEnter="onSearchAddress(details.address, true)"
            @blur="onSearchAddress(details.address, false)"
            after-icon="search"
          />
        </div>
      </div>
      <div
        v-if="this.$store.getters.userIsRoot"
        class="button_container flex align-center"
      >
        <r-button
          width="wide"
          @click="submit"
          :disabled="isLoading || isSave || $store.getters.userIsRootOperator"
          title="Сохранить"
        />
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
    <message ref="message" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import AdminHeader from '../components/AdminHeader';
import RirMap from '../components/RirMap';
import DeleteModal from '../components/DeleteModal';
import Api from '../api/Api';
import { formattedDate } from '../helpers/utils';
import Loader from '../components/Loader';
import Message from '../components/Message';

export default {
  name: 'Electic',
  components: { Loader, AdminHeader, RirMap, Message },
  data() {
    return {
      isCheck: true,
      isSave: true,
      flyButton: null,
      isLoading: false,
      isMapReady: false,
      emptyFormData: {},
      searchAddress: null,
      lat: null,
      lng: null,
      initialData: {},
      details: {
        id: null,
        provider: null,
        providerName: null,
        host: null,
        hostId: null,
        lat: null,
        lng: null,
        address: null,
        name: null,
        type: {
          title: null
        },
      }
    };
  },
  validations: {
    details: {
      type: {
        required
      }
    }
  },
  computed: {
    markerCoords() {
      const { lat } = this;
      const { lng } = this;
      if (lat && lng) {
        return [lng, lat];
      }

      return null;
    },
    isEditPage() {
      return !!this.$route.params.id && !this.isCopy;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  created() {
    this.emptyFormData = this.details;
  },
  mounted() {
    this.flyButton = this.$refs.content.offsetHeight > window.innerHeight;

    if (!this.isEditPage) {
      this.isSave = false;
    }
  },
  activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && this.setData();

    if (!this.isEditPage) {
      this.isSave = false;
      this.isCheck = true;
    }

    this.isMapReady = true;
  },
  deactivated() {
    // this.intervalID && clearInterval(this.intervalID);
    this.isMapReady = false;
  },
  methods: {
    changeType(item) {
    //  this.details.type = item.title;
      this.isChange();
    },
    async onSearchAddress(address) {
      if (!address) return;
      const api = new Api();

      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.details.address;
      }

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.details.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.details.address = res.address;
        }
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.details.lat = res.lat;
        this.details.lng = res.lng;
      }
    },
    async setData() {
      this.isLoading = true;
      const pushData = arr => {
        const item = arr.find(el => el.id === this.$route.params.id);
        this.details = {
          ...this.details,
          ...item
        };
        let coords = this.details.lat && this.details.lng ? [this.details.lat, this.details.lng] : $cityCenter;
        this.onSearchAddress(coords);
        this.initialData = JSON.parse(JSON.stringify(this.details));
        this.isLoading = false;
      };
      if (this.$store.state.electric.hosts.length) {
        pushData(this.$store.state.electric.hosts);
      } else {
        await this.$store.dispatch('getHosts', this.$route.params.id).then(() => {
          pushData(this.$store.state.electric.hosts);
        });
      }
    },
    onMarkerDragEnd(e) {
      if (!this.$store.getters.userIsRoot) return;
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        this.details.lat = coords[0];
        this.details.lng = coords[1];
        this.onSearchAddress(coords);
      }
      this.isChange()
    },

    async onMapClick(coords) {
      if (!this.$store.getters.userIsRoot) return;
      if (coords?.length === 2) {
        this.lng = coords[0];
        this.lat = coords[1];
      }

      this.onSearchAddress(coords);
    },
    resetData() {
      this.details = JSON.parse(JSON.stringify(this.emptyFormData));
    },
    async submit(send = false) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        await new Api()
          .saveZabbixAddress({
            id: this.details.id,
            coordinates: [this.details.lat, this.details.lng]
          })
          .then(async res => {
            if (res.error) {
              this.$refs.message.showMessage(res.error, 'error');
            } else {
              await new Api()
                .operateZabbixHost({
                  action: 'update',
                  item: {
                    id: this.$route.params.id,
                    provider: this.details.provider,
                    name: this.details.name,
                    address: this.details.address,
                    lat: this.details.lat,
                    lng: this.details.lng,
                    type: this.details.type,
                    hostId: this.details.hostId,
                    host: this.details.host,
                    providerName: this.details.providerName,

                  }
                })
                .then(resp => {
                  if (resp.error) {
                    this.$refs.message.showMessage(resp.error, 'error');
                  } else {
                    const r = this.$router.resolve({
                      name: 'index'
                    });
                    window.location.assign(r.href);
                  }
                });
            }
          });
      }
    },
    isChange() { // field
      if (!this.isCopy) {
        // this.isSave = this.initialData[field] === this.details[field];
        this.isSave = !Object.keys(this.details).some(field => this.details[field] !== this.initialData[field]);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.width-max {
  width: max-content !important;
}

.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.rir-input {
      background: rgba(#e14761, 0.08);
    }

    &.rir-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

::v-deep .rir-tooltip__wrapper {
  bottom: calc(100% + 6px);
  top: unset !important;
  max-width: 260px;
  text-align: left;
}

::v-deep .rir-modal__content {
  display: block !important;
}

::v-deep textarea:focus {
  background: transparent !important;
}

.rotate-animation {
  animation: spin .8s linear 0s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
<style>
.rir-date-picker__content {
  min-width: 288px;
  border-radius: 16px;
  padding: 24px;
  height: 244px;
  margin-right: 130px;
  overflow: initial;
}

.rir-date-picker__time {
  position: absolute;
  left: calc(100% + 16px);
  top: 0;
  background: #fff;
  border-radius: 16px;
  padding: 20px 0;
  box-shadow: 0 8px 24px rgba(19, 57, 134, .16);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 112px;
  box-sizing: border-box;
  overflow: hidden
}

.rir-date-picker__time p {
  margin: 0 0 16px 0;
  color: #04153e;
  opacity: .48
}

.rir-date-picker__time .hour {
  margin-left: 16px
}

.rir-date-picker__time .hour, .rir-date-picker__time .minutes {
  padding-right: 12px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
  scrollbar-color: rgba(4, 21, 62, .08) transparent
}

.rir-date-picker__time .hour::-webkit-scrollbar-track, .rir-date-picker__time .minutes::-webkit-scrollbar-track {
  width: 4px;
  background: #fff
}

.rir-date-picker__time .hour::-webkit-scrollbar, .rir-date-picker__time .hour::-webkit-scrollbar-thumb, .rir-date-picker__time .minutes::-webkit-scrollbar, .rir-date-picker__time .minutes::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: .72
}

.rir-date-picker__time .hour > span, .rir-date-picker__time .minutes > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  width: 24px;
  height: 24px;
  color: #113079;
  margin-bottom: 8px;
  border-radius: 4px;
  flex-shrink: 0
}

.rir-date-picker__time .hour > span.active, .rir-date-picker__time .minutes > span.active {
  color: #fff;
  background: #3d75e4
}

.rir-date-picker__time .hour > span:not(.active):hover, .rir-date-picker__time .minutes > span:not(.active):hover {
  background-color: #e4edfb;
  color: #3d75e4
}

.rir-date-picker__time>div {
  display: flex;
  flex-direction: initial;
  width: 100%;
  overflow: auto;
}
.overflow-hidden{
  overflow: hidden;
}
</style>
