import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api/Api';
import electric from './modules/electric';

const api = new Api();
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    userAccesses: {},
    userId: null,
    cityId: null,
    allUsers: []
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUserAccesses(state, user) {
      state.userAccesses = user;
      state.user = user.user;
    },
    setUserId(state, user) {
      state.userId = user.userId;
    },
    setCityId(state, cityId) {
      state.cityId = cityId;
    },
    setAllUsers(state, users) {
      state.allUsers = users;
    }
  },
  getters: {
    userAccesses(state) {
      return state.userAccesses;
    },
    userIsRoot(state) {
      return state.userAccesses.access.role === 'ADMIN';
    },
    userIsRootOperator(state) {
      return state.userAccesses.access.role === 'OPERATOR';
    },
    getActiveUsers(state) {
      if (state?.allUsers) {
        return state?.allUsers?.map(item => ({
          ...item,
          firstName: item?.firstName ? item.firstName : '',
          lastName: item?.lastName ? item.lastName : ''
        })) || [];
      }
      return state?.allUsers?.filter(user => user.active) || [];
    }
  },
  actions: {
    async getUserInfo(ctx) {
      const res = await api.getUserInfo();
      ctx.commit('setUserId', res);
      // console.log('userInfo', res, ctx.state.user);
    },
    async getUserAccesses(ctx) {
      const res = await api.getUserAccesses();
      ctx.commit('setUserAccesses', res);
      // console.log('userInfo', res, ctx.state.user);
    },
    async getCityId(ctx) {
      const res = await api.getCityId();
      ctx.commit('setCityId', res.cityId);
    },
    async getUsers(ctx) {
      const res = await api.getUsers();
      ctx.commit('setAllUsers', res.users);
      return res;
    }
  },
  modules: {
    electric
  }
});
