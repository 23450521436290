import ApiService from './ApiService';

let proxy = '';
if (process.env.NODE_ENV === 'development') {
  proxy = '/proxy';
}
export default class Api extends ApiService {
  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    // const {data} = await this._axios.get(`/auth/rest/user`);
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post(`${proxy}/mob_ajax.php?action=geosearch`, payload);
    return data;
  }

  async getUserAccesses() {
    const { data } = await this._axios.get('/auth/rest/access/POWER_MONITORING');
    return data;
  }

  async getCityId() {
    const { data } = await this._axios.get('/nginxApi.php?get=config');
    return data;
  }

  async getZabbix() {
    const { data } = await this._axios.get('/ajax.php?action=getZabbix&wEvents=1');
    return data;
  }

  async getZabbixProblems(payload) {
    let from = payload?.from ? payload.from : '';
    let to = payload?.to ? payload.to : '';
    const { data } = await this._axios.post('/ajax.php?action=getZabbixProblems', {
      dateFrom: from,
      dateTo: to
    });
    return data;
  }
  async getCityFastOptions() {
    let json = {'fields' : ['zabbixOptions']}
    const { data } = await this._axios.post('/ajax.php?action=getCityFastOptions', json);

    return data;
  }

  async setCityFastOptions(json) {
    const { data } = await this._axios.post('/ajax.php?action=setCityFastOptions', json.json);

    return data;
  }

  async consumeZabbix(id) {
    const { data } = await this._axios.get(`/ajax.php?action=consumeZabbix&taskId=${id}`);
    return data;
  }

  async operateZabbixHost(payload) {
    // JSON: {action:update, item:{hostId:Int,provider:Int,type:String}}
    const { data } = await this._axios.post('/ajax.php?action=operateZabbixHost', payload);
    return data;
  }

  async saveZabbixAddress(payload) {
    // {id:id,coordinates:[lat,lng]}
    const { data } = await this._axios.post('/ajax.php?action=saveZabbixAddress', payload);
    return data;
  }

  async getZabbixTypes() {
    const { data } = await this._axios.get('/ajax.php?action=getZabbixTypes');
    return data;
  }
  async getZabbixProviders() {
    const { data } = await this._axios.get('/ajax.php?action=getZabbixProviders');
    return data;
  }
}
