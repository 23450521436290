var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex mb-6 mt-7"},[_c('r-button-action',{staticClass:"mr-6",attrs:{"title":"Обновить","icon":"update","color":"rocky","size":"sulguni"},nativeOn:{"click":function($event){return _vm.reloadData.apply(null, arguments)}}})],1),_c('div',{staticClass:"flex mb-6 align-center"},[_c('r-select',{staticClass:"flex-1 mr-6",attrs:{"items":[
        {
          title: 'Все',
          id: 0
        },
        ..._vm.$store.state.electric.types
      ],"label":"Тип устройства","return-object":true},model:{value:(_vm.selectedSortType),callback:function ($$v) {_vm.selectedSortType=$$v},expression:"selectedSortType"}}),_c('r-select',{staticClass:"flex-1 mr-6",attrs:{"items":[
        {
          id: 0,
          title: 'Все'
        },
        {
          id: -2,
          title: 'Не задан'
        },
        ..._vm.$store.state.electric.providers
      ],"label":"Тип мониторинга","return-object":true},model:{value:(_vm.selectedSortProvider),callback:function ($$v) {_vm.selectedSortProvider=$$v},expression:"selectedSortProvider"}}),_c('r-input',{staticClass:"flex-1 mr-6",attrs:{"label":"Поиск","after-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}}),_c('r-button-simple',{attrs:{"size":"larishae","icon":"icon-view","icon-size":"20","type":_vm.selectedView === 'map' ? 'secondary' : 'primary'},on:{"click":function($event){_vm.selectedView = 'table'}}}),_c('r-button-simple',{staticClass:"ml-2",attrs:{"size":"larishae","icon":"geopoint","type":_vm.selectedView === 'map' ? 'primary' : 'secondary',"icon-size":"20"},on:{"click":function($event){_vm.selectedView = 'map'}}})],1),(_vm.isLoading)?_c('loader'):[_c('div',{staticClass:"flex mt-2 mb-2"},[_c('div',{staticClass:"ml-auto parmigiano color-titanic opacity-48"},[_vm._v(" "+_vm._s(!!_vm.searchText ? "Найдено" : "Всего")+" "+_vm._s(_vm.countObjects)+" ")])]),(_vm.selectedView === 'map')?_c('div',{key:_vm.filteredObjects.length,ref:"map",staticClass:"objects__map mt-6"},[_c('rir-map',{attrs:{"show-type":""}},[(_vm.filteredObjects.length > 0)?_c('div',_vm._l((_vm.filteredObjects),function(marker,index){return _c('div',{key:`_${marker.id}+${index}`},[(!!marker.lat && !!marker.lng)?_c('ymap-marker',{attrs:{"coords":[marker.lat, marker.lng],"marker-id":`marker_${marker.id}+${index}`,"icon":marker.crashes
                ? _vm.$markerIcon('mapPinRed')
                : _vm.$markerIcon(marker.type.name || 'mapPin'),"options":{ hideIconOnBalloonOpen: false },"cluster-name":marker.crashes ? 'red' : 'main',"balloon":{ body: _vm.clusterBalloon(marker)}}},[_c('balloon-card',{attrs:{"slot":"balloon","marker":marker,"data-id":marker.id},slot:"balloon"})],1):_vm._e()],1)}),0):_vm._e()])],1):_c('div',{staticClass:"objects__list mt-6"},[(_vm.countObjects !== 0)?_c('div',[_c('scroll-table',{attrs:{"list":_vm.filteredObjects,"headings":_vm.headings,"columns-grid":_vm.tableGridColumns,"crashes":_vm.crashes,"start-sorted-column":"providerCrashes","row-link":"/details/"}})],1):_c('not-found')],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }