<template>
  <div>
    <div class="flex mb-6 mt-7">
      <r-button-action
        class="mr-6"
        title="Выгрузить отчёт"
        icon="file-download"
        color="rocky"
        size="sulguni"
        @click.native="csvExport"
      />
      <r-button-action
        class="mr-6"
        title="Контрольная конфигурация"
        icon="settings"
        color="rocky"
        size="sulguni"
        @click.native="editSettings"
      />
    </div>
    <div class="flex mb-6 align-center">
      <r-date-picker
        label="Дата"
        class="flex-1 mr-6"
        v-model.trim="filterStartDate"
        @change="dateStartFrom(filterStartDate, filterEndDate)"
      />
  <!--    <r-date-picker
        label="Период по"
        class="flex-1 mr-6"
        :startDate="filterStartDate"
        v-model.trim="filterEndDate"
        @change="dateStartFrom(filterStartDate, filterEndDate)"
      /> -->
      <!-- <r-select
         class="flex-1 mr-6"
         :items="[
           {
             title: 'Все',
             id: 0
           },
           ...$store.state.electric.types
         ]"
         label="Тип устройства"
         v-model="selectedSortType"
         :return-object="true"
       /> -->
       <r-select
         class="flex-1 mr-6"
         :items="[
           {
             id: 0,
             title: 'Все'
           },
           {
             id: -2,
             title: 'Не задан'
           },
           ...$store.state.electric.providers
         ]"
         label="Тип мониторинга"
         v-model="selectedSortProvider"
         :return-object="true"
       />
       <r-input
         class="flex-1 mr-6"
         label="Поиск"
         v-model.trim="search"
         after-icon="search"
       />
       <r-button-simple
         size="larishae"
         icon="icon-view"
         icon-size="20"
         @click="selectedView = 'table'"
         :type="selectedView === 'map' ? 'secondary' : 'primary'"
       />
       <r-button-simple
         size="larishae"
         class="ml-2"
         icon="geopoint"
         :type="selectedView === 'map' ? 'primary' : 'secondary'"
         icon-size="20"
         @click="selectedView = 'map'"
       />
     </div>
     <loader v-if="isLoading" />
     <template v-else>
       <div class="flex mt-2 mb-2">
         <div class="ml-auto parmigiano color-titanic opacity-48">
           {{ !!searchText ? "Найдено" : "Всего" }} {{ countObjects }}
         </div>
       </div>

       <div
         v-if="selectedView === 'map'"
         class="objects__map mt-6"
         :key="filteredObjects.length"
         ref="map"
       >
         <rir-map
           show-type
         >
           <div v-if="filteredObjects.length > 0">
             <div
               v-for="(marker,index) of filteredObjects"
               :key="`_${marker.id}+${index}`"
             >
               <ymap-marker
                 v-if="!!marker.lat && !!marker.lng"
                 :coords="[marker.lat, marker.lng]"
                 :marker-id="`marker_${marker.id}+${index}`"
                 :icon="$accidentIcon()"
                 :options="{ hideIconOnBalloonOpen: false }"
                 cluster-name="red"
                 :balloon="{ body: clusterBalloon(marker)}"
               >
                 <balloon-card-accident
                   slot="balloon"
                   :marker="marker"
                   :data-id="marker.id"
                 />
               </ymap-marker>
             </div>
           </div>
         </rir-map>
       </div>
       <div
         v-else
         class="objects__list mt-6"
       >
         <div
           v-if="countObjects !== 0"
         >
           <scroll-table
             :list="filteredObjects"
             :headings="headings"
             :columns-grid="tableGridColumns"
             start-sorted-column="device"
             :hide-error="false"
           />
         </div>
         <not-found v-else />
       </div>
     </template>
     <selection-settings
       ref="selection"
     />
   </div>
 </template>

 <script>
 import Loader from './Loader';
 import ScrollTable from './Table';
 import { wordMatch } from '../helpers/utils';
 import SelectDatePicker from "./SelectDatePicker";
 import notFound from '@/components/NotFound.vue';
 import rirMap from "@/components/RirMap.vue";
 import BalloonCardAccident from "@/components/BalloonCardAccident.vue";
 import SelectionSettings from "@/components/SelectionSettings.vue";
 export default {
   name: 'TabCrashes',
   components: {
     BalloonCardAccident,
     rirMap,
     Loader,
     ScrollTable,
     SelectDatePicker,
     notFound,
     SelectionSettings
   },
   data() {
     return {
       selectedView: 'table',
       searchText: '',
       list: [],
       isShowMap: false,
       selectedSortType: {
         id: 0,
         title: 'Все'
       },
       headings: [
         {
           title: 'Устройство',
           field: 'name'
         },
         {
           title: 'Адрес',
           field: 'address'
         },
         {
           title: 'Дата',
           field: 'created'
         },
         {
           title: 'Описание аварии',
           field: 'comment'
         }
       ],
       tableGridColumns: [
         '320px',
         '202px',
         '170px',
         '550px'
       ],
       sortPeriod: [
         {
           id: 'today',
           title: 'Сегодня'
         },
         {
           id: 'yesterday',
           title: 'Вчера'
         },
         {
           id: 'twoDaysAgo',
           title: 'Позавчера'
         }
       ],
       selectedSortPeriod: 'today',
       filterStartDate: null,
       filterEndDate: null,
       selectedSortProvider: {
         id: 0,
         title: 'Все'
       }
     };
   },
   computed: {
     isLoading() {
       return this.$store.state.electric.isLoading;
     },
     filteredObjects() {
       let list = this.$store.getters.getFormattedCrashes;

       // Если заполнена строка поиска
       if (this.searchText) {
         list = list.filter(
           el => wordMatch(el?.columns?.name?.title || '', this.searchText) <= 0.5
             || wordMatch(el?.columns?.comment || '', this.searchText) <= 0.5
             || wordMatch(el?.columns?.address || '', this.searchText) <= 0.5
         );
       }
       if (this.selectedSortType.id !== 0) {
         list = list.filter(el => (el.type == this.selectedSortType.id));
       }

       if (this.selectedSortProvider.id !== 0 && this.selectedSortProvider.id !== -2) {
         list = list.filter(el => (el.provider == this.selectedSortProvider.id));
       }

       if (this.selectedSortProvider.id === -2) {
         list = list.filter(el => (el.columns.name.title == '-'));
       }
       return list;
     },
     countObjects() {
       return this.filteredObjects.length;
     },
     search: {
       get() {
         return this.searchText;
       },
       set(val) {
         if (this.timeoutId) clearTimeout(this.timeoutId);
         this.timeoutId = setTimeout(() => {
           this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
         }, 420);
       }
     }
   },
   activated() {
     this.isShowMap = true;
   },
   deactivated() {
     this.isShowMap = false;
   },
   async created() {
     !this.$store.state.electric.hosts.length && await this.$store.dispatch('getHosts');
     await this.$store.dispatch('getCrashes', {
       from: this.filterStartDate ? this.dateFilter(this.filterStartDate) : '',
       to: this.filterEndDate ? this.dateFilter(this.filterEndDate) : ''
     });
   },
   methods: {
     editSettings() {
       this.$refs.selection.edit();
     },
     dateFilter(e) {
       const date = new Date(e);

       let dd = date.getDate();
       if (dd < 10) dd = `0${dd}`;
       let mm = date.getMonth() + 1;
       if (mm < 10) mm = `0${mm}`;
       const yy = date.getFullYear();

       return `${dd}.${mm}.${yy}`;
     },
     clusterBalloon(marker) {
       return `<div class="r-map-balloon pa-4 pointer">
           <p class="сaprino mb-2 matrix--text">
             ${ marker.columns.created }
           </p>
           <p class="roquefort mb-1">
             ${ marker.columns.name.subtitleAccident }
           </p>
           <div class="flex align-items-center mb-4">
             <p class="сaprino">
               <span>${ marker.columns.comment }</span>
             </p>
           </div>
           <div class="flex align-items-center mozzarella">
             <i class="RIcon mr-2 rir-geopoint_16" style="color: var(--rir-rocky);">
             </i>
             ${ marker.columns.name.address }
           </div>
         </div>`;
     },
     async dateStartFrom(start, end) {
       await this.$store.dispatch('getCrashes', {
         from: start ? this.dateFilter(start) : '',
         to: end ? this.dateFilter(end) : ''
       });
     },
     csvExport() {
       const arrData = this.filteredObjects;
       if (!arrData.length) return;
       let csvContent = 'data:text/csv;charset=utf-8,\uFEFF';

       const head = this.headings.map(el => el.title);
       const cols = arrData.map(el => [
         `${el.columns.name.title} • ${el.columns.name.subtitle}`,
         el?.columns?.created,
         el?.columns?.comment
       ].join(';'));
       csvContent += [
         head.join(';'),
         cols.join('\n')
       ]
         .join('\n')
         .replace(/(^\[)|(\]$)/gm, '');
       const data = encodeURI(csvContent);
       const link = document.createElement('a');
       link.setAttribute('href', data);
       link.setAttribute('download', 'Аварии.csv');
       link.click();
     }
   }
 };
 </script>

 <style lang="scss" scoped>
 .tag{
   padding: 0px 4px;
   background: #E6EBF5;
   border-radius: 2px;
   span{
     opacity: 0.48;
   }
   &.green{
     background: #E3F3D8;
   }
 }
 .r-map-balloon {
   display: block;
   width: 244px;
   font-family: 'Golos UI';
   &__img {
     width: 100%;
     aspect-ratio: 16/10;
     object-fit: cover;
   }

   &__info {
     font-family: 'Golos UI';
     padding: 12px 16px 0;
   }
 }

 .icon-close {
   cursor: pointer;
   position: absolute;
   margin-left: calc(100% - 60px);
   margin-top: -28px;
 }

 .icon-div {
   display: table;
   position: relative;
 }

 .objects {
   &__map {
     height: 700px;
     border-radius: 24px;
     overflow: hidden;
   }
 }

 ::v-deep .rir-popover {
   display: flex;
   align-items: center;

   &__activator {
     width: 100%;
   }

   .rir-icon {
     &.add-icon {
       margin-right: 8px;
     }

     &.data-download__arrow {
       margin-left: 8px;
     }
   }
 }

 .data-download {
   &__wrapper {
     padding: 12px;

     .rir-button {
       display: flex;
       align-items: center;

       .rir-icon {
         margin-right: 8px;
       }
     }
   }
 }

 ::v-deep .rir-popover__content {
   padding: 8px !important;
 }

 ::v-deep .ya-map-point {
   position: absolute;
   left: -4px;
   top: -4px;
   display: block;
   width: 8px;
   height: 8px;
   background-color: #fff;
   border: 2px solid var(--rir-rocky);
   border-radius: 50%;
 }

 ::v-deep .ya-map-point--edge {
   cursor: pointer !important;
   position: absolute;
   left: -3px;
   top: -3px;
   display: block;
   width: 6px;
   height: 6px;
   background-color: var(--rir-rocky);
   border-radius: 50%;
   opacity: 0.8;
 }

 ::v-deep .rir-popover__content {
   background: red;
 }

 .date-picker__fly {
   position: absolute;
   top: 0;
   left: 0;
   opacity: 0;
   pointer-events: none;
 }

 </style>
 <style lang="scss">
 .rir-map-balloon {
   width: 244px;
   font-family: 'Golos UI';

   &__img {
     width: 100%;
     aspect-ratio: 16/10;
     object-fit: cover;
   }

   &__info {
     font-family: 'Golos UI';
     padding: 12px 16px 0;
   }
 }

 .popover_list {
   display: flex;
   flex-direction: column;
   padding: 12px 0;

   &__item {
     display: flex;
     white-space: nowrap;
     align-items: center;
     opacity: 0.72;
     padding: 14px 16px;
     position: relative;

     &:not(.no_hover) {
       &:hover {
         opacity: 1;
         cursor: pointer;

         &::before {
           content: '';
           position: absolute;
           background-color: #3d75e4;
           height: calc(100% - 8px);
           width: 4px;
           top: 4px;
           left: 0;
           border-top-right-radius: 4px;
           border-bottom-right-radius: 4px;
         }
       }
     }

     &.no_hover {
       opacity: 1;
       cursor: pointer;
     }

   }
 }

 </style>
