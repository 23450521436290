import Api from '@/api/Api';
import { formattedDate } from '../../helpers/utils';

const api = new Api();
export default {
  state: () => ({
    hosts: [],
    crashes: [],
    crashesDevices: [],
    isLoading: true,
    types: [],
    providers: [],
    typesSelect: []
  }),
  mutations: {
    setHosts(state, res) {
      state.hosts = res;
    },
    setCrashes(state, res) {
      state.crashes = res;
    },
    setCrashesDevices(state, res) {
      state.crashesDevices = res;
    },
    setLoading(state, bool) {
      state.isLoading = bool;
    },
    setTypes(state, types) {
      types = types.filter(el => el.id != 1337);
      state.types = types.map(el => ({
        id: el.id,
        title: el.name
      }));
      state.typesSelect = types.map(el => ({
        typeId: el.id,
        name: el.name,
        title: ''
      }));
    },
    setProviders(state, providers) {
      providers = providers.filter(el =>  ![5,6,7].find(els => els == Number(el.id)));
      state.providers = providers.filter(el => !el.disabled);
    }
  },
  actions: {
    async getHosts(ctx) {
      ctx.commit('setLoading', true);
      const res = await api.getZabbix();
      ctx.commit('setHosts', res.all || []);
      ctx.commit('setLoading', false);
      return res;
    },
    async getCrashes(ctx, payload) {
      ctx.commit('setLoading', true);
      const res = await api.getZabbixProblems(payload);
      ctx.commit('setCrashes', res.all || []);
      ctx.commit('setLoading', false);
      return res;
    },
    async getCrashesDevices(ctx, payload) {
      ctx.commit('setLoading', true);
      const res = await api.getZabbixProblems(payload);
      ctx.commit('setCrashesDevices', res.all || []);
      ctx.commit('setLoading', false);
      return res;
    },
    async getZabbixTypes(ctx) {
      const res = await api.getZabbixTypes();
      ctx.commit('setTypes', res.all || []);
      return res;
    },
    async getZabbixProviders(ctx) {
      const res = await api.getZabbixProviders();
      ctx.commit('setProviders', res.all || []);
      return res;
    }
  },
  getters: {
    getFormattedHosts(state) {
      return state?.hosts?.map(el => ({
        id: el.id,
        hostId: el.hostId,
        lat: el.lat,
        lng: el.lng,
        type: el.type,
        crashes: state?.crashes.filter(els => els.hostId == el.hostId).length > 0,
        columns: {
          name: {
            title: el.name,
            subtitle: el.host
          },
          address: el.address,
          providerName: el.providerName,
          provider: el.provider/* ,
          status: {
            title: el?.events[0]?.comment,
            subtitle: el?.events[0]?.created
          } */
        }
      }));
    },
    getFormattedCrashes(state) {
      return state?.crashes?.map(el => {
        let hostId = state.hosts.find(host => host.hostId === el.hostId);
        return {
          id: el.id,
          taskId: el.taskId,
          hostId: el?.hostId,
          created: el?.created,
          lat: el?.host?.lat ? el?.host?.lat : null,
          lng: el?.host?.lng ? el?.host?.lng : null,
          provider: el.provider,
          type: el.type,
          columns: {
            name: {
              title: el?.providerName
                ? el.providerName
                : '-',
              subtitle: el?.json?.name ? `${el?.hostId} • ${el?.json?.name}` : `${el?.hostId} • -`,
              subtitleAccident: el?.json?.hosts ? `${el?.json?.hosts[0]?.name}` : '',
              address: el?.host?.address ? el?.host.address : '',
            },
            address: (el?.json?.address || el?.host?.address ?
              (el?.host?.address ? el?.host?.address : el?.json.address) : '-'),
            created: formattedDate(el?.created, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric'
            }),
            comment: el?.comment
          }
        }});
    },
    getFormattedCrashesDevices(state) {
      return state?.crashesDevices?.map(el => {
        let hostId = state.hosts.find(host => host.hostId === el.hostId);
        return {
        id: el.id,
        taskId: el.taskId,
        hostId: el?.hostId,
        created: el?.created,
        lat: el?.host?.lat ? el?.host?.lat : null,
        lng: el?.host?.lng ? el?.host?.lng : null,
        provider: el.provider,
        type: el.type,
        columns: {
          name: {
            title: el?.providerName
              ? el.providerName
              : '-',
            subtitle: el?.json?.name ? `${el?.hostId} • ${el?.json?.name}` : `${el?.hostId} • -`,
            subtitleAccident: el?.json?.hosts ? `${el?.json?.hosts[0]?.name}` : '',
            address: el?.host?.address ? el?.host.address : '',
          },
          address: (el?.json?.address || el?.host?.address ?
            (el?.host?.address ? el?.host?.address : el?.json.address) : '-'),
          created: formattedDate(el?.created, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          }),
          comment: el?.comment
        }
      }});
    },
    getProviders(state) {
      return Array.from(
        new Set(state?.hosts.map(el => JSON.stringify(el.providerName))),
        JSON.parse
      );
    },
    getProvidersForCrashes(state) {
      return Array.from(
        new Set(state?.crashes.map(el => JSON.stringify(state.hosts.find(host => host.hostId === el.hostId).providerName))),
        JSON.parse
      );
    }
  }
};
