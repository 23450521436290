<template>
  <div class="objects">
    <admin-header
      title="Мониторинг электропитания"
      :back="false"
    />
    <r-tabs
      class="mt-7"
      :items="tabs"
      v-model="activeTabId"
    />
    <tab-devices v-show="activeTabId.id === 'devices'" />
    <tab-crashes v-show="activeTabId.id === 'crashes'" />
  </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader.vue';
import TabDevices from '../components/TabDevices';
import TabCrashes from '../components/TabCrashes';

export default {
  name: 'ObjectList',
  components: {
    AdminHeader,
    TabDevices,
    TabCrashes
  },
  data() {
    return {
      tabs: [
        {
          id: 'devices',
          title: 'Устройства'
        },
        {
          id: 'crashes',
          title: 'Аварии'
        }
      ],
      activeTabId: {
        id: 'devices',
        title: 'Устройства'
      }
    };
  },
  async activated() {
    this.activeTabId = this.tabs.find(item => item.id === this.$route.query.tab) || {
      id: 'devices',
      title: 'Устройства'
    };
  }

};
</script>

<style lang="scss" scoped>
</style>
