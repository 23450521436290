<template>
  <div class="modal_container">
    <r-icon
      v-if="icon"
      icon="warning"
      fill="fargo"
      size="56"
    />
    <h1 class="ricotta mb-4 mt-7">
      {{ title || 'Удалить объект?' }}
    </h1>
    <div class="mb-8 font">
      {{ subtitle || 'Это действие невозможно будет отменить' }}
    </div>
    <div class="mt-2 buttons">
      <r-button
        class="flex-1"
        type="secondary"
        @click.native.stop.prevent="$attrs.closeModal()"
        :title="buttons[0] || 'Не удалять'"
        width="wide"
      />
      <r-button
        class="flex-1"
        @click.native.prevent.stop="Delete"
        :title=" buttons[1] || 'Да, удалить'"
        width="wide"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'DeleteModal',
  props: {
    icon: {
      type: Boolean,
      default: true
    },
    action: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    buttons: {
      type: Array,
      default: () => (['Не удалять', 'Да, удалить'])
    }
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    async Delete() {
      this.$props.action();
    }
  }
};
</script>

<style lang="scss" scoped>
.modal_container{
  max-width: 800px;
  margin: 0 auto;
  padding: 10px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.buttons {
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media (max-width: 792px) {
    grid-template-columns: 1fr;
  }
}
</style>
