<template>
  <div id="app">
    <router-view />
    <r-notification />
  </div>
</template>

<script>


export default {
  name: 'AdminApp',
  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps')
      && document.getElementById('vue-yandex-maps').remove();

    // Удаляем ymaps
    window.ymaps && delete window.ymaps;
  },
  created() {
    this.$store.dispatch('getZabbixTypes');
    this.$store.dispatch('getZabbixProviders')
  }
};
</script>
<style lang="scss">
.flex-column{
  flex-direction: column;
}
.align-center{
  align-items: center;
}
</style>
