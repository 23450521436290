<template>
  <router-link
    :to="`/details/${marker.id}`"
    class="r-map-balloon pa-4 pointer"
  >
    <p class="roquefort mb-1">
      {{ marker.columns.name.title }}
    </p>
    <p class="сaprino ekas mb-2">
      {{ marker.columns.providerName }}
    </p>
    <div class="flex align-items-center mb-4">
      <p class="tag сaprino mr-2">
        <span class="bladerunner">{{ marker.id }}</span>
      </p>
      <p class="tag green сaprino">
        <span class="oldboy">{{ marker.columns.name.subtitle }}</span>
      </p>
    </div>
    <div class="flex align-items-center mozzarella">
      <r-icon
        icon="geopoint"
        size="16"
        fill="rocky"
        class="mr-2"
      />
      {{ marker.columns.address }}
    </div>
  </router-link>
</template>

<script>
import { formattedDate } from '../helpers/utils';

export default {
  name: 'BalloonCard',
  props: {
    marker: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getFormattedDate(date) {
      return formattedDate(Number(date), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tag{
  padding: 0px 4px;
  background: #E6EBF5;
  border-radius: 2px;
  span{
    opacity: 0.48;
  }
  &.green{
    background: #E3F3D8;
  }
}
.r-map-balloon {
  display: block;
  width: 244px;
  font-family: 'Golos UI';
  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }

  &__info {
    font-family: 'Golos UI';
    padding: 12px 16px 0;
  }
}

</style>
